import { useTranslation } from 'react-i18next'
import i18n from '../i18n'

export const History = () => {
  const { t } = useTranslation()
  return (
    <div className='overflow-hidden flex flex-col md:flex-row items-center relative justify-center py-12 space-y-6 md:space-y-0 space-x-0 md:space-x-16 text-base my-12'>
      <div
        className='w-full md:w-1/2 flex items-start justify-start h-dvh rounded-xl overflow-hidden'
        id='historyBg'
        style={{
          backgroundImage: 'url(/images/videoimage@2x.png)',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          flexGrow: 1,
        }}
      ></div>
      <div className='flex flex-col items-start justify-start w-full md:w-1/2'>
        <div className='flex flex-col items-start justify-start text-13xl'>
          <b className='relative uppercase inline-block'>{t('Our_history')}</b>
          <div className='relative text-5xl'>{t('since_2023')}</div>
        </div>
        <div className='self-stretch relative leading-[150%] mt-5'>
          {t('Our_history_description')}
        </div>
        <div className='self-stretch relative leading-[150%] mt-5'>
          {t('Our_history_description_2')}
        </div>
      </div>
    </div>
  )
}
