import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import i18n from '../i18n'

export const Reviews = () => {
  const { t } = useTranslation()

  useEffect(() => {
    const script = document.createElement('script')
    script.src = 'https://static.elfsight.com/platform/platform.js'
    script.async = true
    script.setAttribute('data-use-service-core', 'true')
    script.setAttribute('defer', 'true')
    document.body.appendChild(script)

    return () => {
      document.body.removeChild(script)
    }
  }, [])

  return (
    <div
      className='w-full flex flex-col items-start justify-start gap-[40px] mb-10'
      id='reviews'
    >
      <div className='flex flex-col items-start justify-start text-13xl'>
        <b className='relative uppercase inline-block'>
          {t('What_our_guests_say')}
        </b>
        {/* <div className='relative text-5xl'>{t('since_2023')}</div> */}
      </div>
      <div
        class='elfsight-app-c07bdb2e-0ca3-4b44-80aa-4b266ec24e8c'
        data-elfsight-app-lazy
      ></div>
    </div>
  )
}
