import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'

i18n.use(initReactI18next).init({
  resources: {
    en: {
      translation: {
        header: 'Welcome to our hotel',
        rooms_number: '36 rooms of different formats',
        room_types: 'Room types',
        from: 'from',
        Services: 'Services',
        Stable_network_access: 'Stable high-speed network access',
        Location: 'Location',
        location_description:
          'Convenient location in the city center. 3 minutes to subway',
        Kitchen: 'Kitchen',
        Kitchen_description: 'Spacious kitchen and dining area',
        Laundry: 'Laundry',
        Laundry_description: 'Separate laundry and cleaning area',
        Bathroom: 'Bathroom',
        Bathroom_description: 'Rooms with private bathroom and shared bathroom',
        Сoffee_machine: 'Coffee machine',
        Coffee_machine_description: 'Hot coffee available',
        Rooms: 'Rooms',
        Book: 'Book room',
        Our_history: 'Our history',
        since_2023: 'since 2023',
        Our_history_description:
          'GAGARIN CAPSULE HOTEL class – Comfort+, located in the center of Almaty, in the business class housing complex “House on Abay”. The interior of our hotel is designed in LOFT style, which will immerse you in the atmosphere of a spaceship in the universe. The hotel has 3 comfortable luxury rooms, 9 junior suites, and 24 comfortable capsule rooms. All rooms are equipped with air conditioning, refrigerator, wardrobe, table and chairs. A cup of coffee or tea, brewed by you in our kitchen and drunk against the background of the stained glass windows of our hotel, will give you even more vigor and good mood. The hotel cares about the safety of its guests, there is a 24-hour armed live security, a video surveillance system, a panic button, all rooms are equipped with electronic locks, both code-based and fingerprint-based. For a safer and more comfortable stay of guests, persons under the influence of alcohol or drugs are not allowed into the hotel, and drinking alcoholic beverages is prohibited.',
        Our_history_description_2:
          'GAGARIN CAPSULE HOTEL is called so in honor of the first cosmonaut Yuri Gagarin, and also because it is located at Gagarin 124, block 5, corner of Abai Avenue. Abay Avenue is the main avenue of the city of Almaty, has good traffic flow, along which a large number of public transport passes, such as metro, buses, trolleybuses, where you can easily get to any point in the city. For your convenience, we offer breakfast, hotel slippers, shampoo, soap, and shower gel, hair dryer, tea, coffee, towels, laundry, all free of charge. Below our hotel there is a cafe of oriental cuisine, where dishes such as pilaf, manti, lagman and much more are offered, and for guests of our hotel there is an exclusive 15% discount',
        Almaty_KZ: 'Almaty, Kazakhstan',
        address: 'Residential complex House on Abay, Gagarin Avenue, 124',
        title_1: 'capsule hotel in Almaty',
        title_2: '36 rooms of different formats',
        Booking: 'Booking',
        submit_request:
          'Submit your booking request, wait for email confirmation',
        Guests: 'Guests',
        room_type: 'Room type',
        Reserve_room: 'Reserve room',
        Name: 'Name',
        What_our_guests_say: 'What our guests say',
        Reviews: 'Reviews',
        Gallery: 'Gallery',
        Write_to_us: 'Write to us',
        Capsule_bed: 'Capsule bed',
        Vip_capsule: 'Vip capsule',
        Room_full: 'Room',
        Breakfast: 'Breakfast',
        Tea_coffee: 'Tea, coffee',
        Laundry: 'Laundry',
        Towels: 'Towels',
        Shampoo: 'Hypoallergenic shampoo',
        Air_conditioning: 'Air conditioning',
        Microwave: 'Microwave',
        TV: 'TV',
        Table: 'Personal table',
        Kitchenware: 'Kitchenware',
        Washing_machine: 'Washing machine',
      },
    },
    ru: {
      translation: {
        header: 'Добро пожаловать в наш отель',
        rooms_number: '36 номеров различных форматов',
        room_types: 'Типы номеров',
        from: 'от',
        Services: 'Услуги',
        Stable_network_access: 'Стабильный высокоскоростной доступ в сеть',
        Location: 'Расположение',
        location_description:
          'Удобное расположение в центре города. 3 минуты до метро',
        Kitchen: 'Кухня',
        Kitchen_description: 'Просторная кухня и столовая',
        Laundry: 'Прачечная',
        Laundry_description: 'Отдельная прачечная и зона уборки',
        Bathroom: 'Ванная комната',
        Bathroom_description:
          'Номера с собственной ванной комнатой и общей ванной комнатой',
        Сoffee_machine: 'Кофемашина',
        Coffee_machine_description: 'Горячий кофе в наличии',
        Rooms: 'Номера',
        Book: 'Забронировать номер',
        Our_history: 'Наша история',
        since_2023: 'с 2023 года',
        Our_history_description:
          'GAGARIN CAPSULE HOTEL класса – Comfort+, расположенный в центре города Алматы, в жилищном комплексе бизнес – класса «Дом на Абая». Интерьер нашего отеля выполнен в стиле LOFT, который вас погрузит в атмосферу космического корабля во вселенной. Отель располагает 3 комфортабельными номерами класса Люкс, 9 номеров класса полулюкс, и 24 комфортабельных капсульных номеров. Все номера оснащены, кондиционером, холодильником, шкафом, столом со стульями. Чашка кофе или чая, заваренная Вами на нашей кухне, и выпитая на фоне витражных окон нашего отеля, придаст Вам еще больше бодрости и хорошего настроения. Отель заботится о безопасности своих гостей, имеется круглосуточная вооруженная живая охрана, система видеонаблюдения, тревожная кнопка, все номера оснащены электронными замками как на кодовой основе, так и по отпечаткам пальцев. Для более безопасного и комфортного пребывания гостей в отель не допускаются лица в состоянии алкогольного или наркотического опьянения, и запрещено распитие алкогольных напитков.',
        Our_history_description_2:
          'GAGARIN CAPSULE HOTEL, называется так, в честь первого космонавта Юрия Гагарина, а так же потому что находится по адресу Гагарина 124, блок 5, угол проспекта Абая. Проспект Абая основной проспект города Алматы, имеет хорошую транспортную проходимость, по которому проходит большое количество общественного транспорта, такие как метро, автобусы, троллейбусы, где Вы с легкостью доберетесь до любой точки города. Для вашего удобства мы предлагаем завтраки, отельные тапочки, шампунь, мыло, и гель для душа, фен, чай, кофе, полотенца, прачечная, и все это на бесплатной основе. Под нашим отелем находится кафе восточной кухни, где предлагаются такие блюда как плов, манты, лагман и много другого, и для постояльцев нашего отеля действует эксклюзивная скидка 15%',
        Almaty_KZ: 'Алматы, Казахстан',
        address: 'Жилой комплекс Дом на Абая, проспект Гагарина, 124',
        title_1: 'капсульный отель в Алматы',
        title_2: '36 номеров различных форматов',
        booking: 'Бронирование',
        submit_request:
          'Отправьте свой запрос на бронирование, дождитесь подтверждения по электронной почте',
        Guests: 'Гости',
        room_type: 'Тип номера',
        Reserve_room: 'Забронировать номер',
        Name: 'Имя',
        What_our_guests_say: 'Что говорят наши гости',
        Reviews: 'Отзывы',
        Gallery: 'Галерея',
        Write_to_us: 'Напишите нам',
        Capsule_bed: 'Капсульная кровать',
        Vip_capsule: 'Vip капсула',
        Room_full: 'Номер',
        Breakfast: 'Завтрак',
        Tea_coffee: 'Чай, кофе',
        Laundry: 'Прачечная',
        Towels: 'Полотенца',
        Shampoo: 'Гипоаллергенный шампунь',
        Air_conditioning: 'Кондиционер',
        Microwave: 'Микроволновая печь',
        TV: 'Телевизор',
        Table: 'Персональный стол',
        Kitchenware: 'Кухонные принадлежности',
        Washing_machine: 'Стиральная машина',
      },
    },
    kz: {
      translation: {
        header: 'Біздің қонақ үйімізге қош келдіңіз',
        rooms_number: '36 ретінде форматтары бар өріс',
        room_types: 'Өрістердің түрлері',
        from: 'бастап',
        Services: 'Қызметтер',
        Stable_network_access: 'Стабильді жылдам қосымшаға қол жеткізу',
        Location: 'Орналасуы',
        location_description:
          'Қаланың ортасында орналасқан жағдайда. Метродан 3 минут',
        Kitchen: 'Ас үйі',
        Kitchen_description: 'Үлкен ас үйі және ас жемісі алаңы',
        Laundry: 'Кір жуу',
        Laundry_description: 'Арнайы кір жуу және тазалау алаңы',
        Bathroom: 'Құмар қабаты',
        Bathroom_description:
          'Жеке құмар қабат және қолданбалы құмар қабаттар бар өрістер',
        Сoffee_machine: 'Кофемашина',
        Coffee_machine_description: 'Жылы кофе қолжетімді',
        Rooms: 'Өрістер',
        Book: 'Өрісті брондау',
        Our_history: 'Біздің тарихымыз',
        since_2023: '2023 жылдан бастап',
        Our_history_description:
          'GAGARIN CAPSULE HOTEL класы – Comfort+, Алматының орталығында, «Абайдағы үй» бизнес-класс тұрғын үй кешенінде орналасқан. Қонақ үйіміздің интерьері LOFT стилінде жасалған, ол сізді ғаламдағы ғарыш кемесі атмосферасына батырады. Қонақ үйде 3 жайлы люкс бөлмесі, 9 кіші люкс және 24 жайлы капсула бөлмесі бар. Барлық бөлмелер кондиционер, тоңазытқыш, гардероб, үстел және орындықтармен жабдықталған. Біздің ас үйде сіз қайнатқан және қонақүйдің витраждары аясында ішкен бір кесе кофе немесе шай сізге одан да күшті және жақсы көңіл-күй сыйлайды. Қонақ үй өз қонақтарының қауіпсіздігіне қамқорлық жасайды, тәулік бойы қарулы күзет, бейнебақылау жүйесі, дүрбелең түймесі бар, барлық нөмірлер электронды құлыптармен жабдықталған, кодтық және саусақ ізімен жабдықталған. Қонақтардың қауіпсіз әрі жайлы болуы үшін қонақүйге алкогольдік немесе есірткілік масаң күйдегі адамдарды кіргізбейді, алкоголді сусындарды ішуге тыйым салынады.',
        Our_history_description_2:
          'GAGARIN CAPSULE HOTEL бірінші ғарышкер Юрий Гагариннің құрметіне, сондай-ақ Гагарин 124, 5 блок, Абай даңғылы қиылысында орналасқандықтан осылай аталады. Абай даңғылы – Алматы қаласының негізгі даңғылы, көлік қозғалысы жақсы, оның бойымен метро, ​​автобус, троллейбус сияқты көптеген қоғамдық көліктер өтеді, ол жерден қаланың кез келген нүктесіне оңай жетуге болады. Сізге ыңғайлы болу үшін біз таңғы ас, қонақ үйге арналған тәпішке, сусабын, сабын және душқа арналған гель, фен, шай, кофе, сүлгі, кір жуу, барлығын тегін ұсынамыз. Қонақ үйіміздің астында палау, манты, лағман және т.б. сияқты тағамдар ұсынылатын шығыс асханасының кафесі орналасқан, ал біздің қонақүйдің қонақтары үшін эксклюзивті 15% жеңілдік бар.',
        Almaty_KZ: 'Алматы, Қазақстан',
        address: 'Абай үйі, Гагарин алға, 124',
        title_1: 'Алматыдағы капсульді қонақ үйі',
        title_2: '36 ретінде форматтары бар өріс',
        booking: 'Брондау',
        submit_request:
          'Брондау құпиясын жіберіңіз, электрондық пошта арқылы растаңыз',
        Guests: 'Қонақтар',
        room_type: 'Өрістің түрі',
        Reserve_room: 'Өрісті брондау',
        Name: 'Аты',
        What_our_guests_say: 'Қонақтарымыз не деп айтады',
        Reviews: 'Пікірлер',
        Gallery: 'Галерея',
        Write_to_us: 'Бізге жазыңыз',
        Capsule_bed: 'Капсула ұстасы',
        Vip_capsule: 'Vip капсула',
        Room_full: 'Үй',
        Breakfast: 'Таңғы ас',
        Tea_coffee: 'Шай, кофе',
        Laundry: 'Кір жуу',
        Towels: 'Сүре',
        Shampoo: 'Гипоаллергенді шампунь',
        Air_conditioning: 'Кондиционер',
        Microwave: 'Микротолқынды пеш',
        TV: 'Теледидар',
        Table: 'Жеке үстел',
        Kitchenware: 'Ас үй бұйымдары',
        Washing_machine: 'Кір жуу машинасы',
      },
    },
  },
  lng: 'en',
  fallbackLng: 'en',
  interpolation: {
    escapeValue: false,
  },
})

export default i18n
