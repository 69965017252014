import { useTranslation } from 'react-i18next'
import i18n from '../i18n'
import { Room } from './Room'

export const Rooms = () => {
  const { t } = useTranslation()

  const room_full_services =
    t('Breakfast') +
    ',' +
    t('Tea_coffee') +
    ',' +
    t('Laundry') +
    ',' +
    t('Towels') +
    ',' +
    t('Shampoo') +
    ',' +
    t('TV') +
    ',' +
    t('Microwave') +
    ',' +
    t('Fridge') +
    ',' +
    t('Air_conditioning') +
    ',' +
    t('Table') +
    ',' +
    t('Kitchenware') +
    ',' +
    t('Washing_machine')

  const room_vip_services =
    t('Breakfast') +
    ',' +
    t('Tea_coffee') +
    ',' +
    t('Laundry') +
    ',' +
    t('Towels') +
    ',' +
    t('Shampoo') +
    ',' +
    t('Air_conditioning')

  const room_capsule_services =
    t('Breakfast') +
    ',' +
    t('Tea_coffee') +
    ',' +
    t('Laundry') +
    ',' +
    t('Towels') +
    ',' +
    t('Shampoo') +
    ',' +
    t('Air_conditioning')

  return (
    <div
      className='w-full flex flex-col items-start justify-start gap-[40px]'
      id='rooms'
    >
      <div className='flex flex-col items-start justify-start gap-[10px]'>
        <b className='relative uppercase inline-block w-full'>
          {t('room_types')}
        </b>
        <div className='relative text-5xl'>{t('rooms_number')}</div>
      </div>

      <div className='w-full flex flex-row text-base flex-nowrap overflow-x-auto '>
        <Room
          img='/images/gallery/50.jpeg'
          title={t('Room_full')}
          price='25 000 '
          rooms_number='3'
          services={room_full_services}
        />
        <Room
          img='/images/gallery/42.jpeg'
          title={t('Vip_capsule')}
          price='15,000 '
          rooms_number='9'
          services={room_vip_services}
        />
        <Room
          img='/images/gallery/48.jpeg'
          title={t('Capsule_bed')}
          price='8 000 - 9 000'
          rooms_number='24'
          services={room_capsule_services}
        />
      </div>
    </div>
  )
}
