import { useTranslation } from 'react-i18next'
import i18n from '../i18n'

export const Room = ({ img, title, price, rooms_number, services }) => {
  const { t } = useTranslation()

  const servicesArray = services.split(',')

  let servicesList = ''
  servicesArray.forEach(
    (service) =>
      (servicesList +=
        '<span class="px-2 py-1 bg-slate-100 text-slate-500 text-sm rounded">' +
        service +
        '</span>')
  )
  return (
    <div className='w-full md:w-1/3 inline-block p-4'>
      <div className='flex flex-col items-start justify-start space-y-6 pb-6'>
        <img
          className='relative rounded-3xs w-full h-[240px] object-cover'
          alt=''
          src={img}
        />
        <div className='flex flex-col items-start justify-start space-y-2'>
          <b className='relative w-full  inline-block w-[301px] text-xl'>
            {title}
          </b>
          <div className='relative leading-[150%] font-medium text-steelblue inline-block  inline-block w-[301px]'>
            {t('from')} {price} KZT
          </div>
          <div className='relative text-sm leading-[110%] inline-block  inline-block w-[301px]'>
            {rooms_number} rooms
          </div>
        </div>
        <div
          className='shrink-0 flex flex-row flex-wrap items-start justify-start gap-[10px]'
          dangerouslySetInnerHTML={{ __html: servicesList }}
        ></div>
      </div>
    </div>
  )
}
