import { useTranslation } from 'react-i18next'
import { ImageGallery } from 'react-image-grid-gallery'
import i18n from '../i18n'

export const GalleryBlock = () => {
  const { t } = useTranslation()

  const imagesArray = [
    {
      alt: 'Gagarin Capsule Hotel',
      caption: '',
      src: 'images/gallery/1.jpeg',
    },
    {
      alt: 'Gagarin Capsule Hotel',
      caption: '',
      src: 'images/gallery/2.jpeg',
    },
    {
      alt: 'Gagarin Capsule Hotel',
      caption: '',
      src: 'images/gallery/3.jpeg',
    },
    {
      alt: 'Gagarin Capsule Hotel',
      caption: '',
      src: 'images/gallery/4.jpeg',
    },
    {
      alt: 'Gagarin Capsule Hotel',
      caption: '',
      src: 'images/gallery/5.jpeg',
    },
    {
      alt: 'Gagarin Capsule Hotel',
      caption: '',
      src: 'images/gallery/6.jpeg',
    },
    {
      alt: 'Gagarin Capsule Hotel',
      caption: '',
      src: 'images/gallery/7.jpeg',
    },
    {
      alt: 'Gagarin Capsule Hotel',
      caption: '',
      src: 'images/gallery/8.jpeg',
    },
    {
      alt: 'Gagarin Capsule Hotel',
      caption: '',
      src: 'images/gallery/9.jpeg',
    },
    {
      alt: 'Gagarin Capsule Hotel',
      caption: '',
      src: 'images/gallery/10.jpeg',
    },
    {
      alt: 'Gagarin Capsule Hotel',
      caption: '',
      src: 'images/gallery/11.jpeg',
    },
    {
      alt: 'Gagarin Capsule Hotel',
      caption: '',
      src: 'images/gallery/12.jpeg',
    },
    {
      alt: 'Gagarin Capsule Hotel',
      caption: '',
      src: 'images/gallery/13.jpeg',
    },
    {
      alt: 'Gagarin Capsule Hotel',
      caption: '',
      src: 'images/gallery/14.jpeg',
    },
    {
      alt: 'Gagarin Capsule Hotel',
      caption: '',
      src: 'images/gallery/15.jpeg',
    },
    {
      alt: 'Gagarin Capsule Hotel',
      caption: '',
      src: 'images/gallery/16.jpeg',
    },
    {
      alt: 'Gagarin Capsule Hotel',
      caption: '',
      src: 'images/gallery/17.jpeg',
    },
    {
      alt: 'Gagarin Capsule Hotel',
      caption: '',
      src: 'images/gallery/18.jpeg',
    },
    {
      alt: 'Gagarin Capsule Hotel',
      caption: '',
      src: 'images/gallery/19.jpeg',
    },
    {
      alt: 'Gagarin Capsule Hotel',
      caption: '',
      src: 'images/gallery/20.jpeg',
    },
    {
      alt: 'Gagarin Capsule Hotel',
      caption: '',
      src: 'images/gallery/21.jpeg',
    },
    {
      alt: 'Gagarin Capsule Hotel',
      caption: '',
      src: 'images/gallery/22.jpeg',
    },
    {
      alt: 'Gagarin Capsule Hotel',
      caption: '',
      src: 'images/gallery/23.jpeg',
    },
    {
      alt: 'Gagarin Capsule Hotel',
      caption: '',
      src: 'images/gallery/24.jpeg',
    },
    {
      alt: 'Gagarin Capsule Hotel',
      caption: '',
      src: 'images/gallery/25.jpeg',
    },
    {
      alt: 'Gagarin Capsule Hotel',
      caption: '',
      src: 'images/gallery/26.jpeg',
    },
    {
      alt: 'Gagarin Capsule Hotel',
      caption: '',
      src: 'images/gallery/27.jpeg',
    },
    {
      alt: 'Gagarin Capsule Hotel',
      caption: '',
      src: 'images/gallery/28.jpeg',
    },
    {
      alt: 'Gagarin Capsule Hotel',
      caption: '',
      src: 'images/gallery/29.jpeg',
    },
    {
      alt: 'Gagarin Capsule Hotel',
      caption: '',
      src: 'images/gallery/30.jpeg',
    },
    {
      alt: 'Gagarin Capsule Hotel',
      caption: '',
      src: 'images/gallery/31.jpeg',
    },
    {
      alt: 'Gagarin Capsule Hotel',
      caption: '',
      src: 'images/gallery/32.jpeg',
    },
    {
      alt: 'Gagarin Capsule Hotel',
      caption: '',
      src: 'images/gallery/33.jpeg',
    },
    {
      alt: 'Gagarin Capsule Hotel',
      caption: '',
      src: 'images/gallery/34.jpeg',
    },
    {
      alt: 'Gagarin Capsule Hotel',
      caption: '',
      src: 'images/gallery/35.jpeg',
    },
    {
      alt: 'Gagarin Capsule Hotel',
      caption: '',
      src: 'images/gallery/36.jpeg',
    },
    {
      alt: 'Gagarin Capsule Hotel',
      caption: '',
      src: 'images/gallery/37.jpeg',
    },
    {
      alt: 'Gagarin Capsule Hotel',
      caption: '',
      src: 'images/gallery/38.jpeg',
    },
    {
      alt: 'Gagarin Capsule Hotel',
      caption: '',
      src: 'images/gallery/39.jpeg',
    },
    {
      alt: 'Gagarin Capsule Hotel',
      caption: '',
      src: 'images/gallery/40.jpeg',
    },
    {
      alt: 'Gagarin Capsule Hotel',
      caption: '',
      src: 'images/gallery/41.jpeg',
    },
    {
      alt: 'Gagarin Capsule Hotel',
      caption: '',
      src: 'images/gallery/42.jpeg',
    },
    {
      alt: 'Gagarin Capsule Hotel',
      caption: '',
      src: 'images/gallery/43.jpeg',
    },
    {
      alt: 'Gagarin Capsule Hotel',
      caption: '',
      src: 'images/gallery/44.jpeg',
    },
    {
      alt: 'Gagarin Capsule Hotel',
      caption: '',
      src: 'images/gallery/45.jpeg',
    },
    {
      alt: 'Gagarin Capsule Hotel',
      caption: '',
      src: 'images/gallery/46.jpeg',
    },
    {
      alt: 'Gagarin Capsule Hotel',
      caption: '',
      src: 'images/gallery/47.jpeg',
    },
    {
      alt: 'Gagarin Capsule Hotel',
      caption: '',
      src: 'images/gallery/48.jpeg',
    },
    {
      alt: 'Gagarin Capsule Hotel',
      caption: '',
      src: 'images/gallery/49.jpeg',
    },
    {
      alt: 'Gagarin Capsule Hotel',
      caption: '',
      src: 'images/gallery/50.jpeg',
    },
  ]

  return (
    <div
      className='w-full flex flex-col items-start justify-start gap-[40px]'
      id='gallery'
    >
      <div className='flex flex-col items-start justify-start text-13xl'>
        <b className='relative uppercase inline-block'>{t('Gallery')}</b>
        {/* <div className='relative text-5xl'>{t('since_2023')}</div> */}
      </div>
      <ImageGallery
        imagesInfoArray={imagesArray}
        columnWidth={100}
        gapSize={24}
      />
    </div>
  )
}
