import { useTranslation } from 'react-i18next'
import i18n from '../i18n'
import React from 'react'
import Popup from 'reactjs-popup'
// import 'reactjs-popup/dist/index.css'

export default () => {
  const { t } = useTranslation()
  return (
    <div className='max-w-screen-xl flex flex-col md:flex-row items-center justify-start md:justify-between'>
      <div className='flex flex-col items-start justify-start gap-[30px]'>
        <div className='hidden flex-row items-start justify-start gap-[10px]'>
          <div className='relative rounded-[50%] [background:linear-gradient(#008bd2,_#008bd2),_#008bd2] w-2.5 h-2.5' />
          <div className='relative rounded-[50%] [background:linear-gradient(#e8e9ea,_#e8e9ea),_#008bd2] w-2.5 h-2.5' />
          <div className='relative rounded-[50%] [background:linear-gradient(#e8e9ea,_#e8e9ea),_#008bd2] w-2.5 h-2.5' />
        </div>
        <b className='relative uppercase inline-block'>{t('title_1')}</b>
        <div className='relative text-5xl uppercase inline-block'>
          {t('title_2')}
        </div>
        <div className='flex flex-row flex-wrap items-start justify-start gap-[20px] text-sm text-white pb-4 md:pb-0'>
          <a
            href='#orderForm'
            className='bg-steelblue shrink-0 flex flex-row items-center justify-center py-2.5 px-10'
          >
            <span className='relative uppercase font-medium'>{t('Book')}</span>
          </a>
          <a
            href='#location'
            className='bg-white shrink-0 flex flex-row items-center justify-center py-2.5 px-5 gap-[10px] text-steelblue border-[1px] border-solid border-steelblue'
          >
            <svg
              width='14'
              height='18'
              viewBox='0 0 14 18'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                d='M7.5832 17.55C9.38672 15.293 13.5 9.82266 13.5 6.75C13.5 3.02344 10.4766 0 6.75 0C3.02344 0 0 3.02344 0 6.75C0 9.82266 4.11328 15.293 5.9168 17.55C6.34922 18.0879 7.15078 18.0879 7.5832 17.55ZM6.75 4.5C7.34674 4.5 7.91903 4.73705 8.34099 5.15901C8.76295 5.58097 9 6.15326 9 6.75C9 7.34674 8.76295 7.91903 8.34099 8.34099C7.91903 8.76295 7.34674 9 6.75 9C6.15326 9 5.58097 8.76295 5.15901 8.34099C4.73705 7.91903 4.5 7.34674 4.5 6.75C4.5 6.15326 4.73705 5.58097 5.15901 5.15901C5.58097 4.73705 6.15326 4.5 6.75 4.5Z'
                fill='#008BD2'
              />
            </svg>

            <div className='relative uppercase font-medium'>
              {t('Location')}
            </div>
          </a>
          <a
            href='https://wa.me/77028411028'
            className='bg-white shrink-0 flex flex-row items-center justify-center py-2.5 px-5 gap-[10px] text-steelblue border-[1px] border-solid border-steelblue'
          >
            <svg
              xmlns='http://www.w3.org/2000/svg'
              width='18'
              height='18'
              viewBox='0 0 448 512'
            >
              <path
                fill='#008BD2'
                d='M380.9 97.1C339 55.1 283.2 32 223.9 32c-122.4 0-222 99.6-222 222 0 39.1 10.2 77.3 29.6 111L0 480l117.7-30.9c32.4 17.7 68.9 27 106.1 27h.1c122.3 0 224.1-99.6 224.1-222 0-59.3-25.2-115-67.1-157zm-157 341.6c-33.2 0-65.7-8.9-94-25.7l-6.7-4-69.8 18.3L72 359.2l-4.4-7c-18.5-29.4-28.2-63.3-28.2-98.2 0-101.7 82.8-184.5 184.6-184.5 49.3 0 95.6 19.2 130.4 54.1 34.8 34.9 56.2 81.2 56.1 130.5 0 101.8-84.9 184.6-186.6 184.6zm101.2-138.2c-5.5-2.8-32.8-16.2-37.9-18-5.1-1.9-8.8-2.8-12.5 2.8-3.7 5.6-14.3 18-17.6 21.8-3.2 3.7-6.5 4.2-12 1.4-32.6-16.3-54-29.1-75.5-66-5.7-9.8 5.7-9.1 16.3-30.3 1.8-3.7 .9-6.9-.5-9.7-1.4-2.8-12.5-30.1-17.1-41.2-4.5-10.8-9.1-9.3-12.5-9.5-3.2-.2-6.9-.2-10.6-.2-3.7 0-9.7 1.4-14.8 6.9-5.1 5.6-19.4 19-19.4 46.3 0 27.3 19.9 53.7 22.6 57.4 2.8 3.7 39.1 59.7 94.8 83.8 35.2 15.2 49 16.5 66.6 13.9 10.7-1.6 32.8-13.4 37.4-26.4 4.6-13 4.6-24.1 3.2-26.4-1.3-2.5-5-3.9-10.5-6.6z'
              />
            </svg>

            <div className='relative uppercase font-medium'>
              {t('Write_to_us')}
            </div>
          </a>
        </div>
      </div>
      <Popup
        trigger={
          <img
            className='object-cover w-4/5 md:w-1/2 hover:cursor-pointer'
            alt=''
            src='/images/head-image@2x.png'
          />
        }
        position='right center'
        modal
        nested
      >
        {(close) => (
          <div className='modal'>
            <button className='close absolute' onClick={close}>
              &times;
            </button>
            <div
              style={{
                padding: '65% 0 0 0',
                position: 'relative',
                background: 'none',
              }}
              className='p-0 m-0 relative bg-none w-full h-full'
            >
              <iframe
                src='https://player.vimeo.com/video/911461866?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479'
                frameBorder='0'
                allow='autoplay; fullscreen; picture-in-picture'
                style={{
                  position: 'absolute',
                  top: '0',
                  left: '0',
                  width: '100%',
                  height: '100%',
                  background: 'none',
                }}
                title='Gagarin capsule hotel'
              ></iframe>
            </div>
            <script src='https://player.vimeo.com/api/player.js'></script>
          </div>
        )}
      </Popup>
    </div>
  )
}
