import { useTranslation } from 'react-i18next'
import i18n from '../i18n'

export const Location = () => {
  const { t } = useTranslation()
  const phone = '77028411028'
  const phoneLink = `tel:${phone}`
  const mail = 'gagarincapsulehotel@gmail.com'
  const mailLink = `mailto:${mail}`
  return (
    <div
      className='relative w-full md:flex item-center justify-end'
      id='location'
    >
      <div className='relative md:absolute t-0 l-0 mb-6 object-cover w-full z-0'>
        <iframe
          className='rounded-xl'
          src='https://yandex.ru/map-widget/v1/?um=constructor%3A7d57fefcaf04a4fbd63e56de505eb3d5a36e5951f95bc8e1ce969af1282dfe56&amp;source=constructor'
          width='100%'
          height='500'
          frameborder='0'
        ></iframe>
      </div>

      <div className='relative z-30 p-0 md:p-12 rounded-tl-xl mb-6 md:mb-52 mt-0 md:mt-12 rounded-tr-none rounded-br-none rounded-bl-xl bg-white w-full md:w-1/2 overflow-hidden flex flex-row items-center justify-center'>
        <div className='flex flex-col items-start justify-start'>
          <div className=' flex flex-col items-start justify-start space-y-6 '>
            <h4 className='relative uppercase inline-block w-full font-bold'>
              {t('Location')}
            </h4>
            <div className='relative text-5xl'>{t('Almaty_KZ')}</div>
          </div>
          <div className=' flex flex-col items-start justify-start space-y-4 mt-6 text-base text-darkgray font-ubuntu'>
            <div className='flex flex-row items-start justify-start  space-x-4'>
              <img
                className='w-6 h-6 object-cover'
                alt=''
                src='/images/atoms--icons--system--location@2x.png'
              />
              <div className='flex-1 relative'>{t('address')}</div>
            </div>
            <div className='flex flex-row space-x-4'>
              <img
                className=' w-6 h-6 object-cover'
                alt=''
                src='/images/atoms--icons--system--phone@2x.png'
              />
              <a href={phoneLink} className=''>
                {phone}
              </a>
            </div>
            <div className='flex flex-row space-x-4'>
              <img
                className='relative w-6 h-6 object-cover'
                alt=''
                src='/images/atoms--icons--system--mail-inverse@2x.png'
              />
              <a href={mailLink} className='relative'>
                {mail}
              </a>
            </div>
          </div>
        </div>
        <img
          className='relative w-1/2 h-full object-cover'
          alt=''
          src='/images/art06818-1@2x.png'
        />
      </div>
    </div>
  )
}
