import { useState } from 'react'
import emailjs from '@emailjs/browser'
import { useTranslation } from 'react-i18next'
import i18n from '../i18n'

export const GuestForm = () => {
  const { t } = useTranslation()

  const [startDate, setStartDate] = useState(
    new Date().toLocaleDateString('US-us')
  )
  const [endDate, setEndDate] = useState(new Date().toLocaleDateString('US-us'))
  const [name, setName] = useState(null)
  const [phone, setPhone] = useState(null)
  const [email, setEmail] = useState(null)
  const [guestNumber, setGuestNumber] = useState(1)
  const [roomType, setRoomType] = useState(null)

  const sendEmail = (e) => {
    let form = new FormData()

    console.log('sending email')

    if (name === null || name === '') {
      alert('Please enter your name')
      return
    }

    if (phone === null || phone === '') {
      alert('Please enter your phone')
      return
    }

    if (guestNumber === null || guestNumber === '') {
      alert('Please enter number of guests')
      return
    }

    if (roomType === null || roomType === '') {
      alert('Please enter room type')
      return
    }

    if (startDate === null || startDate === '') {
      alert('Please enter start date')
      return
    }

    if (endDate === null || endDate === '') {
      alert('Please enter end date')
      return
    }

    document.getElementById('sendLabel').innerText = 'Sending...'

    form.append('name', name)
    form.append('phone', phone)
    form.append('email', email)
    form.append('guestNumber', guestNumber)
    form.append('roomType', roomType)
    form.append('startDate', startDate)
    form.append('endDate', endDate)
    form = Object.fromEntries(form.entries())

    emailjs
      .send('service_jojrjkm', 'template_nwo5kce', form, 'tCyqq3UmvjbjR3bf7')
      .then(
        (result) => {
          console.log(result.text)
          console.log('message sent!')
          document.getElementById('sendLabel').innerText = 'Sent!'
          setTimeout(() => {
            document.getElementById('sendLabel').innerText = 'Reserve room'
          }, 3000)
        },
        (error) => {
          console.log(error.text)
          console.log('error sending message, try again!')
        }
      )
  }
  return (
    <div className='flex flex-col items-center justify-center w-full h-full space-y-6'>
      <div className='rounded-xl bg-darkslateblue w-full p-6 my-10 overflow-hidden flex flex-col items-center justify-start py-10 box-border gap-[30px] text-center text-sm text-white'>
        <div className='w-full flex flex-col items-center justify-start gap-[10px] text-13xl'>
          <b className='relative uppercase inline-block w-[585px]'>
            {t('Booking')}
          </b>
          <div className='relative text-5xl'>{t('submit_request')}</div>
        </div>

        <div className='w-full md:w-1/2 flex flex-row items-start justify-start'>
          <div className='flex-1 shrink-0 flex flex-row items-center justify-start py-2.5 px-5 gap-[10px] border-b-[1px] border-solid border-white'>
            {/* <img
              className='relative w-5 h-5 overflow-hidden shrink-0 object-cover'
              alt=''
              src='/images/inputiconuserlargesolid@2x.png'
            /> */}
            <input
              className='invert relative leading-[100%] inline-block w-full bg-transparent w-full text-black border-none outline-none placeholder-gray'
              type='text'
              name='name'
              placeholder={t('Name')}
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </div>
        </div>

        <div className='w-full md:w-1/2 flex flex-row items-start justify-start gap-[30px]'>
          <div className='flex-1 shrink-0 flex flex-row items-center justify-start py-2.5 px-5 gap-[10px] border-b-[1px] border-solid border-white'>
            <input
              className='invert relative leading-[100%] inline-block w-full bg-transparent w-full text-black border-none outline-none placeholder-gray'
              type='number'
              name='phone'
              value={phone}
              placeholder={t('Phone, ex: 1234567890')}
              onChange={(e) => setPhone(e.target.value)}
            />
          </div>
          <div className='flex-1 shrink-0 flex flex-row items-center justify-start py-2.5 px-5 gap-[10px] border-b-[1px] border-solid border-white'>
            <input
              className='invert relative leading-[100%] inline-block w-full bg-transparent w-full text-black border-none outline-none placeholder-gray'
              type='email'
              name='email'
              value={email}
              placeholder={t('E-mail, ex: name@example.com')}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
        </div>

        <div className='w-full md:w-1/2 flex flex-row items-start justify-start gap-[30px]'>
          <div className='flex-1 shrink-0 flex flex-row items-center justify-between py-2.5 px-5 border-b-[1px] border-solid border-white'>
            <select
              className='bg-transparent text-white w-full'
              onChange={(e) => setGuestNumber(e.target.value)}
            >
              <option value>{t('Guests')}</option>
              <option value='1'>1</option>
              <option value='2'>2</option>
              <option value='3'>3+</option>
            </select>
          </div>
          <div className='flex-1 md:w-1/2 shrink-0 flex flex-row items-center justify-between py-2.5 px-5 border-b-[1px] border-solid border-white'>
            <select
              className='bg-transparent text-white w-full'
              onChange={(e) => setRoomType(e.target.value)}
            >
              <option value>{t('room_type')}</option>
              <option value='Standard double room'>Standard double room</option>
              <option value='Capsule room'>Capsule room</option>
              <option value='Queen room'>Queen room</option>
              <option value='Jupiter king-size room'>
                Jupiter king-size room
              </option>
            </select>
          </div>
        </div>

        <div className='w-full md:w-1/2 flex flex-row items-start justify-start gap-[30px]'>
          <div className='flex-1 shrink-0 flex flex-row items-center justify-start py-2.5 px-5 gap-[10px] border-b-[1px] border-solid border-white'>
            <input
              className='invert relative leading-[100%] inline-block w-[77px] shrink-0 bg-transparent w-full text-black border-none outline-none'
              type='date'
              name='dateFrom'
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
            />
          </div>
          <div className='flex-1 shrink-0 flex flex-row items-center justify-start py-2.5 px-5 gap-[10px] border-b-[1px] border-solid border-white'>
            <input
              className='invert relative leading-[100%] inline-block w-[77px] shrink-0 bg-transparent w-full text-black border-none outline-none'
              type='date'
              name='dateTo'
              value={endDate}
              onChange={(e) => setEndDate(e.target.value)}
            />
          </div>
        </div>

        <div
          onClick={sendEmail}
          className='bg-steelblue flex flex-row items-center justify-center py-2.5 px-10 text-left hover:cursor-pointer'
        >
          <div className='relative uppercase font-medium' id='sendLabel'>
            {t('Reserve_room')}
          </div>
        </div>
      </div>
    </div>
  )
}
