// import { YMInitializer } from '@appigram/react-yandex-metrika'
import { Footer } from './components/Footer'
import { GalleryBlock } from './components/GalleryBlock'
import { GuestForm } from './components/GuestForm'
import { Header } from './components/Header'
import { History } from './components/History'
import LeadBlock from './components/LeadBlock'
import { Location } from './components/Location'
import { Reviews } from './components/Reviews'
import { Rooms } from './components/Rooms'
import { Services } from './components/Services'

const App = () => {
  return (
    <div className='scroll-smooth	bg-white w-full h-full flex flex-col items-center justify-center px-4 md:px-16 overflow-hidden text-left text-[48px] text-gray font-inter'>
      <Header />

      <LeadBlock />

      <div className='max-w-screen-xl flex flex-col items-center justify-start w-full text-13xl'>
        <Rooms />
        <History />
        <GalleryBlock />
        <Services />
        <Reviews />
        <Location />
        <GuestForm />
        <Footer />
      </div>
      {/* <YMInitializer accounts={[96415620]} /> */}
    </div>
  )
}

export default App
