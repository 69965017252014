import { useTranslation } from 'react-i18next'
import i18n from '../i18n'

export const Services = () => {
  const { t } = useTranslation()
  return (
    <div
      className='overflow-hidden flex flex-col md:flex-row items-center justify-center py-12 space-y-0 md:space-y-16 space-x-0 text-base'
      id='services'
    >
      <div className='w-full md:w-1/2 flex flex-col items-start justify-start'>
        <div className='self-stretch flex flex-col items-center justify-start text-13xl'>
          <b className='relative uppercase inline-block'>{t('Services')}</b>
          <div className='relative text-5xl'></div>
        </div>
        <div className='w-full grid grid-cols-3 gap-4'>
          <div className='flex-1 rounded-xl bg-white flex flex-col items-center justify-start py-[30px] px-2.5 gap-[10px]'>
            <img
              className='relative w-[50px] h-[50px] overflow-hidden shrink-0 object-cover'
              alt=''
              src='/images/serviceswifi@2x.png'
            />
            <b className='relative leading-[150%]'>Wi-Fi</b>
            <div className='self-stretch relative text-center text-sm leading-[24px]'>
              {t('Stable_network_access')}
            </div>
          </div>
          <div className='flex-1 rounded-xl bg-white flex flex-col items-center justify-start py-[30px] px-2.5 gap-[10px]'>
            <img
              className='relative w-[50px] h-[50px] overflow-hidden shrink-0 object-cover'
              alt=''
              src='/images/serviceslocationdotsolid@2x.png'
            />
            <b className='relative leading-[150%]'>{t('Location')}</b>
            <div className='self-stretch relative text-center text-sm leading-[24px]'>
              {t('location_description')}
            </div>
          </div>
          <div className='flex-1 rounded-xl bg-white flex flex-col items-center justify-start py-[30px] px-2.5 gap-[10px]'>
            <img
              className='relative w-[50px] h-[50px] overflow-hidden shrink-0 object-cover'
              alt=''
              src='/images/serviceskitchensetsolid@2x.png'
            />
            <b className='relative leading-[150%]'>{t('Kitchen')}</b>
            <div className='self-stretch relative text-center text-sm leading-[24px]'>
              {t('Kitchen_description')}
            </div>
          </div>
          <div className='flex-1 rounded-xl bg-white flex flex-col items-center justify-start py-[30px] px-2.5 gap-[10px]'>
            <img
              className='relative w-[50px] h-[50px] overflow-hidden shrink-0 object-cover'
              alt=''
              src='/images/servicessoapsolid@2x.png'
            />
            <b className='relative leading-[150%]'>{t('Laundry')}</b>
            <div className='self-stretch relative text-center text-sm leading-[24px]'>
              {t('Laundry_description')}
            </div>
          </div>
          <div className='flex-1 rounded-xl bg-white flex flex-col items-center justify-start py-[30px] px-2.5 gap-[10px]'>
            <img
              className='relative w-[50px] h-[50px] overflow-hidden shrink-0 object-cover'
              alt=''
              src='/images/servicesbathsolid@2x.png'
            />
            <b className='relative leading-[150%]'>{t('Bathroom')}</b>
            <div className='self-stretch relative text-center text-sm leading-[24px]'>
              {t('Bathroom_description')}
            </div>
          </div>
          <div className='flex-1 rounded-xl bg-white flex flex-col items-center justify-start py-[30px] px-2.5 gap-[10px]'>
            <img
              className='relative w-[50px] h-[50px] overflow-hidden shrink-0 object-cover'
              alt=''
              src='/images/servicesmughotsolid@2x.png'
            />
            <b className='relative leading-[150%]'>{t('Сoffee_machine')}</b>
            <div className='self-stretch relative text-center text-sm leading-[24px]'>
              {t('Coffee_machine_description')}
            </div>
          </div>
        </div>
      </div>
      <div className='w-full md:w-1/2'>
        <img
          className='relative rounded-xl w-full object-cover'
          alt=''
          src='/images/rectangle-5@2x.png'
        />
      </div>
    </div>
  )
}
