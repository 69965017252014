import { useTranslation } from 'react-i18next'
import i18n from '../i18n'

export const Header = () => {
  const phone = '77028411028'
  const phoneLink = `tel:${phone}`
  const instagramLink = 'https://www.instagram.com/gagarincapsulehotel/'
  const telegramLink = 'https://t.me/+77028411028'
  const mail = 'gagarincapsulehotel@gmail.com'

  const { i18n } = useTranslation()
  const changeLanguage = (language) => {
    i18n.changeLanguage(language)
  }

  const { t } = useTranslation()
  const currentLanguage = i18n.language

  return (
    <header className='w-full'>
      <nav class='bg-white border-gray-200 px-4 lg:px-6 py-2.5 dark:bg-gray-800 w-full'>
        <div class='flex flex-wrap justify-between items-center mx-auto max-w-screen-xl'>
          <a href='/' class='flex items-center'>
            <img
              src='/images/gch-logotype-horizontal-1@2x.png'
              class='mr-3 md:h-20 sm:h-14'
              alt='Gagarin Hotel'
            />
          </a>

          <div
            class='justify-between items-end w-full lg:flex flex-col items-end lg:w-auto justify-center lg:order-1 uppercase pt-6 md:pt-0 mt-5 lg:mt-0'
            id='mobile-menu-2'
          >
            <div className='flex flex-row items-end space-y-4 w-auto'>
              <div className='w-full flex flex-col md:flex-row items-center justify-between space-y-6 md:space-y-0'>
                <div className='text-sm flex flex-row '>
                  <a
                    href='#'
                    className={`shrink-0 flex flex-row items-center justify-center p-2.5  border-[1px] border-solid ${
                      currentLanguage === 'en'
                        ? 'border-steelblue text-steelblue'
                        : 'border-white text-black'
                    }`}
                    onClick={() => changeLanguage('en')}
                  >
                    <span className='relative uppercase font-medium'>EN</span>
                  </a>
                  <a
                    href='#'
                    className={`shrink-0 flex flex-row items-center justify-center p-2.5 border-[1px] border-solid ${
                      currentLanguage === 'kz'
                        ? 'border-steelblue text-steelblue'
                        : 'border-white text-black'
                    } hover:text-steelblue hover:border-[1px] hover:border-solid hover:border-steelblue`}
                    onClick={() => changeLanguage('kz')}
                  >
                    <span className='relative uppercase font-medium'>KZ</span>
                  </a>
                  <a
                    href='#'
                    className={`shrink-0 flex flex-row items-center justify-center p-2.5  border-[1px] border-solid ${
                      currentLanguage === 'ru'
                        ? 'border-steelblue text-steelblue'
                        : 'border-white text-black'
                    } hover:text-steelblue hover:border-[1px] hover:border-solid hover:border-steelblue`}
                    onClick={() => changeLanguage('ru')}
                  >
                    <span className='relative uppercase font-medium'>RU</span>
                  </a>
                </div>
                <div className='flex flex-col space-y-5 space-x-0 items-center flex-wrap md:flex-row md:space-y-0 md:space-x-5  md:items-end'>
                  <a
                    href={'mailto:' + mail}
                    className='shrink-0 flex flex-row items-center justify-start py-0 px-[7px] gap-[5px]'
                  >
                    <img
                      className='relative w-4 h-4 object-cover'
                      alt=''
                      src='/images/atoms--icons--system--mail-inverse@2x.png'
                    />
                    <span className='relative uppercase text-sm'>{mail}</span>
                  </a>
                  <a
                    href={phoneLink}
                    className='shrink-0 flex flex-row items-center justify-start gap-[5px]'
                  >
                    <img
                      className='relative w-4 h-4 object-cover'
                      alt=''
                      src='/images/icon@2x.png'
                    />
                    <span className='relative uppercase text-sm'>{phone}</span>
                  </a>
                  <a
                    href={instagramLink}
                    target='_blank'
                    className='shrink-0 flex flex-row items-center justify-start gap-[5px]'
                  >
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      viewBox='0 0 448 512'
                      width={18}
                      height={18}
                    >
                      <path
                        fill='#008BD2'
                        d='M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z'
                      />
                    </svg>
                    <span className='relative uppercase text-sm'>
                      @gagarincapsulehotel
                    </span>
                  </a>
                  <a
                    href={telegramLink}
                    target='_blank'
                    className='shrink-0 flex flex-row items-center justify-start gap-[5px]'
                  >
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      viewBox='0 0 496 512'
                      width={18}
                      height={18}
                    >
                      <path
                        fill='#008BD2'
                        d='M248 8C111 8 0 119 0 256S111 504 248 504 496 393 496 256 385 8 248 8zM363 176.7c-3.7 39.2-19.9 134.4-28.1 178.3-3.5 18.6-10.3 24.8-16.9 25.4-14.4 1.3-25.3-9.5-39.3-18.7-21.8-14.3-34.2-23.2-55.3-37.2-24.5-16.1-8.6-25 5.3-39.5 3.7-3.8 67.1-61.5 68.3-66.7 .2-.7 .3-3.1-1.2-4.4s-3.6-.8-5.1-.5q-3.3 .7-104.6 69.1-14.8 10.2-26.9 9.9c-8.9-.2-25.9-5-38.6-9.1-15.5-5-27.9-7.7-26.8-16.3q.8-6.7 18.5-13.7 108.4-47.2 144.6-62.3c68.9-28.6 83.2-33.6 92.5-33.8 2.1 0 6.6 .5 9.6 2.9a10.5 10.5 0 0 1 3.5 6.7A43.8 43.8 0 0 1 363 176.7z'
                      />
                    </svg>
                    <span className='relative uppercase text-sm'>Telegram</span>
                  </a>
                </div>
              </div>
            </div>

            <ul class='flex flex-col mt-4 font-medium lg:flex-row lg:space-x-8 lg:mt-0 text-sm items-center justify-center space-y-2 md:space-y-0'>
              <li>
                <a
                  href='#rooms'
                  class='block py-2 pr-4 pl-3 text-gray-700 hover:bg-gray-50 lg:hover:bg-transparent lg:border-0 lg:hover:text-primary-700 lg:p-0'
                >
                  {t('Rooms')}
                </a>
              </li>
              <li>
                <a
                  href='#services'
                  class='block py-2 pr-4 pl-3 text-gray-700 hover:bg-gray-50 lg:hover:bg-transparent lg:border-0 lg:hover:text-primary-700 lg:p-0'
                >
                  {t('Services')}
                </a>
              </li>
              <li>
                <a
                  href='#reviews'
                  class='block py-2 pr-4 pl-3 text-gray-700 hover:bg-gray-50 lg:hover:bg-transparent lg:border-0 lg:hover:text-primary-700 lg:p-0'
                >
                  {t('Reviews')}
                </a>
              </li>
              <li>
                <a
                  href='#location'
                  class='block py-2 pr-4 pl-3 text-gray-700 hover:bg-gray-50 lg:hover:bg-transparent lg:border-0 lg:hover:text-primary-700 lg:p-0'
                >
                  {t('Location')}
                </a>
              </li>
              <li>
                <a
                  href='#orderForm'
                  class='block bg-steelblue flex flex-row items-center text-white justify-center py-2 px-8 border border-solid border-white text-md hover:opacity-90 uppercas'
                >
                  {t('Book')}
                </a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </header>
  )
}
